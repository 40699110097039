<template>
  <div class="channel-card">
    <div class="mobile">
      <div class="channel-img">
        <avatar :avatar-img="channel.featuredImage" size="xxl"></avatar>
      </div>

      <div class="channel-info">
        <img :src="channel.icon" />
        <p class="sub-text-s2">{{ channel.title }}</p>
      </div>

      <div class="channel-meta">
        <div class="post">
          <span class="sub-text-s3 text-gray-second">게시물</span>
          <h5>{{ state.postCount }}</h5>
        </div>
        <hr />
        <div class="subscriber">
          <span class="sub-text-s3 text-gray-second">팔로워</span>
          <h5>{{ state.subscriberCount }}</h5>
        </div>
      </div>
    </div>

    <div class="tablet">
      <div class="channel-img">
        <avatar :avatar-img="channel.featuredImage" size="xxl"></avatar>
      </div>

      <div class="channel-info">
        <div class="name">
          <img :src="channel.icon" />
          <p class="sub-text-s2">{{ channel.title }}</p>
        </div>

        <div class="channel-meta">
          <div class="post">
            <span class="sub-text-s3 text-gray-second">게시물</span>
            <h4>{{ state.postCount }}</h4>
          </div>

          <text-divider
            height="48px"
            color="#E6EAEF"
            margin="0 20px"
          ></text-divider>

          <div class="subscriber">
            <span class="sub-text-s3 text-gray-second">팔로워</span>
            <h4>{{ state.subscriberCount }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="menu-btn">
      <button-dropdown placement="bottom-end">
        <template #button>
          <button class="dropdown-btn">
            <more-verti-icon fill-color="#000000"></more-verti-icon>
          </button>
        </template>
        <template #dropdownList>
          <ul class="dropdown-list">
            <li v-close-popper class="dropdown-item">
              <button class="sub-text-s2" @click="actions.getChannelContents()">
                동영상 가져오기
              </button>
            </li>
            <li v-close-popper class="dropdown-item">
              <button
                class="sub-text-s2 text-red-50"
                @click="actions.deleteChannel()"
              >
                채널 삭제
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>
  </div>
</template>

<script>
import Helper from "@/helper/index";
import Avatar from "../../../../components/console/avatars/Avatar";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import MoreVertiIcon from "../../../../components/console/icons/MoreVertiIcon";
import { computed, reactive } from "vue";
import helper from "@/helper";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import { useStore } from "vuex";
import swal from "@/helper/swal";

export default {
  name: "ChannelItem",
  components: { TextDivider, MoreVertiIcon, ButtonDropdown, Avatar },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      postCount: computed(() => {
        return props.channel.counts.post
          ? helper.priceFormat(props.channel.counts.post)
          : "-";
      }),
      subscriberCount: computed(() => {
        return props.channel.counts.subscriber
          ? helper.priceFormat(props.channel.counts.subscriber)
          : "-";
      }),
    });

    const actions = {
      deleteChannel: () => {
        store
          .dispatch("channels/deleteChannel", {
            channelResourceId: props.channel.resourceId,
          })
          .then(() => {
            store.dispatch("contents/getContents");
            swal.errorToast("채널이 삭제 되었습니다.");
          });
      },
      getChannelContents: () => {
        store
          .dispatch("channels/postChannelGather", {
            channelResourceId: props.channel.resourceId,
          })
          .then(() => {
            swal.messageAlert("동영상 가져오기가 완료되었습니다!!");
          });
      },
    };

    return { state, actions, Helper };
  },
};
</script>

<style src="./style.css" scoped></style>
