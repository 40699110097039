<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <!--	  todo 추후 개발 예정 -->
    <!--    <div v-if="false">-->
    <!--      <button-->
    <!--        class="btn btn-primary me-2"-->
    <!--        v-on:click="actions.onBtnOAuth('instagram')"-->
    <!--      >-->
    <!--        인스타그램-->
    <!--      </button>-->
    <!--      <button-->
    <!--        class="btn btn-primary me-2"-->
    <!--        v-on:click="actions.onBtnOAuth('youtube')"-->
    <!--      >-->
    <!--        유튜브-->
    <!--      </button>-->
    <!--      <button-->
    <!--        class="btn btn-primary me-2"-->
    <!--        v-on:click="actions.onBtnOAuth('tiktok')"-->
    <!--      >-->
    <!--        틱톡-->
    <!--      </button>-->
    <!--    </div>-->

    <div v-if="!state.pageLoading">
      <page-header
        title="SNS 피드"
        description="유튜브 콘텐츠를 수집해서 나만의 포트폴리오를 만들어보세요. (추후 다른 SNS 지원 예정)"
        guide-link="https://bigc-im.notion.site/SNS-6c692aca566941f0b19f6b7a56beafbd"
      ></page-header>

      <div class="page-wrapper">
        <div v-if="state.channels.length === 0" class="register-card-wrapper">
          <div class="channel-register-card">
            <div class="inner-card">
              <button class="sub-title-s3" @click="actions.openChannelModal()">
                유튜브 콘텐츠 가져오기 <plus-icon></plus-icon>
              </button>
            </div>
          </div>

          <div class="coming-soon-card">
            <ul>
              <li>
                <img
                  :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_youtube.svg`"
                  width="32"
                  style="
                    filter: invert(83%) sepia(13%) saturate(150%)
                      hue-rotate(180deg) brightness(108%) contrast(79%);
                  "
                />
              </li>
              <li>
                <img
                  :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_twitter.svg`"
                  width="32"
                  style="
                    filter: invert(83%) sepia(13%) saturate(150%)
                      hue-rotate(180deg) brightness(108%) contrast(79%);
                  "
                />
              </li>
              <li>
                <img
                  :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_tiktok.svg`"
                  width="32"
                  style="
                    filter: invert(83%) sepia(13%) saturate(150%)
                      hue-rotate(180deg) brightness(108%) contrast(79%);
                  "
                />
              </li>
              <li>
                <img
                  :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_brunch.svg`"
                  width="32"
                  style="
                    filter: invert(83%) sepia(13%) saturate(150%)
                      hue-rotate(180deg) brightness(108%) contrast(79%);
                  "
                />
              </li>
              <li>
                <img
                  :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_twitch.svg`"
                  width="32"
                  style="
                    filter: invert(83%) sepia(13%) saturate(150%)
                      hue-rotate(180deg) brightness(108%) contrast(79%);
                  "
                />
              </li>
            </ul>
            <p class="sub-text-s2 text-gray-second">다른 SNS도 준비중이에요</p>
          </div>
        </div>

        <ul class="channel-list">
          <li
            v-for="channel in state.channels"
            :key="`channel-${channel.resourceId}`"
            class="channel-item"
            :class="{ single: state.channels.length === 1 }"
          >
            <channel-item :channel="channel"></channel-item>
          </li>
        </ul>

        <div class="tab-section">
          <div class="tab-btn-wrapper">
            <button-tab
              text="전체"
              :active="state.activeTab === 'all'"
              @click="actions.changeTab('all')"
            ></button-tab>
            <button-tab
              text="유튜브"
              :active="state.activeTab === 'youtube'"
              @click="actions.changeTab('youtube')"
            ></button-tab>
          </div>
        </div>

        <div class="column-head">
          <span class="col1 sub-text-s3 text-gray-second">SNS 콘텐츠</span>
          <span class="col2 sub-text-s3 text-gray-second">상태</span>
          <span class="col3 sub-text-s3 text-gray-second">등록일</span>
        </div>

        <div class="content-wrapper">
          <empty-list
            v-if="state.contents.length === 0"
            icon-image="/assets/images/channels/contents-empty.png"
            :text="`가져온 콘텐츠가 없어요.<br />유튜브 주소를 연결해서 콘텐츠를 모아보세요.`"
            :is-button="true"
            btn-text="유튜브 콘텐츠 가져오기"
            @btnAction="actions.openChannelModal()"
          >
            <template #buttonIcon>
              <arrow-icon></arrow-icon>
            </template>
          </empty-list>

          <ul class="content-list">
            <li
              v-for="content in state.contents"
              :key="`channel-content-${content.resourceId}`"
              class="content-item"
            >
              <channel-content-item :content="content"></channel-content-item>
            </li>
          </ul>

          <pagination
            v-if="state.contentsMeta.pagination.lastPage > 1"
            :pagination="state.contentsMeta.pagination"
            @getPageData="(page) => actions.onBtnContentsPage(page)"
          ></pagination>
        </div>
      </div>
    </div>

    <teleport :disabled="true">
      <channel-register-modal
        v-if="state.showChannelModal"
        @hideModal="actions.closeChannelRegisterModal()"
        @complete="actions.registerComplete()"
      ></channel-register-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive } from "vue";
import ApiService from "@/api";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import Helper from "@/helper/index";
import PageHeader from "../../../components/console/headers/PageHeader";
import { useStore } from "vuex";
import ChannelRegisterModal from "./ChannelRegisterModal/ChannelRegisterModal";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ChannelContentItem from "./ChannelContentItem/ChannelContentItem";
import ChannelItem from "./ChannelItem/ChannelItem";
import swal from "@/helper/swal";
import Pagination from "../../../components/console/pagination/Pagination";
import PlusIcon from "../../../components/console/icons/PlusIcon";
import EmptyList from "../../../components/console/empties/EmptyList";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";

export default {
  name: "Channels",
  components: {
    ArrowIcon,
    EmptyList,
    PlusIcon,
    Pagination,
    ChannelItem,
    ChannelContentItem,
    PageLoading,
    ChannelRegisterModal,
    PageHeader,
    ButtonTab,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      pageLoading: true,
      channels: computed(() => {
        return store.getters["channels/channels"];
      }),
      contents: computed(() => {
        return store.getters["contents/contents"];
      }),
      contentsMeta: computed(() => {
        return store.getters["contents/contentsMeta"];
      }),
      channelForm: {
        url: null,
      },
      showChannelModal: false,
      activeTab: "all",
    });

    onMounted(async () => {
      await store.dispatch("channels/getChannels");
      await store.dispatch("contents/getContents");

      state.pageLoading = false;
    });

    const getChannels = () => {
      const params = {
        isSync: true,
      };
      ApiService.getChannels(params).then((response) => {
        state.channels = response.data.data;
      });
    };

    const getContents = (page) => {
      state.contents = [];
      const params = {
        page: page,
      };
      store.dispatch("contents/getContents", params);
    };

    const postChannel = () => {
      const payload = {
        url: state.channelForm.url,
      };
      ApiService.postChannel(payload).then((response) => {
        const result = response.data;
        if (result.success) {
          getChannels();
          state.channelForm.url = null;
        } else {
          alert(result.message);
        }
      });
    };

    const postChannelMeta = (channel) => {
      ApiService.postChannelMeta(channel.resourceId).then(() => {
        getChannels();
      });
    };

    const postChannelGather = (channel) => {
      ApiService.postChannelGather(channel.resourceId).then(() => {
        getChannels();
        getContents(1);
      });
    };

    const receiveMessage = async (e) => {
      console.log(e);
      await postOAuthCallback(e.data);
      // await window.removeEventListener("message", receiveMessage, false);
    };

    const postOAuthCallback = async (data) => {
      const appUrl = process.env.VUE_APP_URL;
      const provider = data.provider;
      const code = data.code;
      if (code) {
        const payload = {
          code: code,
          redirectUri: appUrl + data.redirectUri,
        };
        ApiService.postOAuthCallback(provider, payload).then((response) => {
          console.log(response);
        });
      }
    };

    const channelConnect = async (provider) => {
      await window.addEventListener("message", receiveMessage, false);
      let options =
        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=500,height=800,top=0,left=0";
      const appUrl = process.env.VUE_APP_URL;
      const redirectUri = `${appUrl}/new/channels/callback/${provider}`;
      let oAuthUrl = null;

      if (provider === "instagram") {
        const clientId = process.env.VUE_APP_INSTAGRAM_CLIENT_ID;
        oAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
      }

      if (provider === "youtube") {
        const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
        const scopes = "https://www.googleapis.com/auth/youtube.readonly";
        oAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code`;
      }

      if (provider === "tiktok") {
        const clientId = process.env.VUE_APP_TIKTOK_CLIENT_ID;
        const csrfState = Math.random().toString(36).substring(2);
        const scopes = "user.info.basic,video.list";
        oAuthUrl = `https://www.tiktok.com/auth/authorize?client_key=${clientId}&redirect_uri=${redirectUri}&scope=${scopes}&response_type=code&state=${csrfState}`;
      }

      const popup = window.open(oAuthUrl, "_blank", options);
      console.log(popup);
    };

    const actions = {
      onBtnOAuth: async (provider) => {
        await channelConnect(provider);
      },
      onBtnChannelDelete: (channel) => {
        ApiService.deleteChannel(channel.resourceId).then(() => {
          getChannels();
        });
      },
      onBtnContentsPage: (page) => {
        console.log(page);
        getContents(page);
      },
      onBtnChannelCreate: () => {
        postChannel();
      },
      onBtnPostChannelMeta: (channel) => {
        postChannelMeta(channel);
      },
      onBtnPostChannelGather: (channel) => {
        postChannelGather(channel);
      },
      openChannelModal: () => {
        state.showChannelModal = true;
      },
      closeChannelRegisterModal: () => {
        state.showChannelModal = false;
      },
      registerComplete: () => {
        swal.messageAlert("동영상 가져오기가 완료되었습니다!!");
        state.showChannelModal = false;
      },
      changeTab: (tabName) => {
        state.activeTab = tabName;
      },
    };

    return { state, actions, Helper, proxy };
  },
};
</script>
<style src="./style.css" scoped></style>
