<template>
  <div class="content-card">
    <!-- Mobile Tablet -->
    <div class="mobile">
      <div v-if="content.channelCode" class="channel">
        <img
          :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_youtube.svg`"
        />
        <span class="sub-text-s3 text-gray-second">유튜브</span>
      </div>

      <div ref="featuredImageMobile" class="featured-img"></div>

      <div class="content-info">
        <p class="title sub-text-s2">{{ content.title }}</p>
        <p class="description b-text-2 text-gray-second">
          {{ content.description }}
        </p>
      </div>

      <div class="content-meta">
        <div class="status">
          <span class="b-text-3 text-gray-second">상태</span>
          <span>
            <button-dropdown distance="8">
              <template #button>
                <button class="dropdown-btn b-text-1">
                  {{ content.isShow ? "공개" : "비공개" }}
                  <i class="fa-sharp fa-solid fa-caret-down"></i>
                </button>
              </template>
              <template #dropdownList>
                <ul>
                  <li v-close-popper>
                    <button
                      :disabled="content.isShow"
                      @click="actions.contentShow()"
                    >
                      공개
                    </button>
                  </li>
                  <li v-close-popper>
                    <button
                      :disabled="!content.isShow"
                      @click="actions.contentHide"
                    >
                      비공개
                    </button>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </span>
        </div>
        <div class="create-at">
          <span class="b-text-3 text-gray-second">등록일</span>
          <span class="b-text-1">{{ state.createdAt }}</span>
        </div>
      </div>
    </div>

    <!--	PC  -->
    <div class="pc">
      <div class="col1">
        <div ref="featuredImagePC" class="featured-img"></div>
        <div class="content-info">
          <div class="channel">
            <img
              :src="`https://s3.ap-northeast-2.amazonaws.com/${proxy.$const.bigcAwsBucket}/statics/commons/icon/ic_youtube.svg`"
            />
            <span class="sub-text-s3 text-gray-second">유튜브</span>
          </div>
          <div class="text">
            <p class="title sub-text-s2">{{ content.title }}</p>
            <p class="description b-text-2 text-gray-second">
              {{ content.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="col2">
        <button-dropdown placement="bottom-end">
          <template #button>
            <button class="public-toggle-btn sub-text-s2">
              {{ content.isShow ? "공개" : "비공개" }}
              <i class="fa-sharp fa-solid fa-caret-down"></i>
            </button>
          </template>
          <template #dropdownList>
            <ul>
              <li v-close-popper>
                <button
                  :disabled="content.isShow"
                  @click="actions.contentShow()"
                >
                  공개
                </button>
              </li>
              <li v-close-popper>
                <button
                  :disabled="!content.isShow"
                  @click="actions.contentHide()"
                >
                  비공개
                </button>
              </li>
            </ul>
          </template>
        </button-dropdown>
      </div>
      <div class="col3">
        <span class="sub-text-s2">{{ state.createdAt }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import { dateFormat } from "@/helper/date";
import { useStore } from "vuex";

export default {
  name: "ChannelContentItem",
  components: { ButtonDropdown },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const featuredImageMobile = ref();
    const featuredImagePC = ref();

    const state = reactive({
      createdAt: computed(() => {
        return dateFormat(props.content.createdAt);
      }),
    });

    onMounted(() => {
      featuredImageMobile.value.style.backgroundImage = `url(${props.content.featuredImage})`;
      featuredImagePC.value.style.backgroundImage = `url(${props.content.featuredImage})`;
    });

    const actions = {
      contentShow: () => {
        store.dispatch("contents/updateContentShow", {
          resourceId: props.content.resourceId,
          data: {
            isShow: true,
          },
        });
      },
      contentHide: () => {
        store.dispatch("contents/updateContentShow", {
          resourceId: props.content.resourceId,
          data: {
            isShow: false,
          },
        });
      },
    };

    return { state, actions, featuredImageMobile, featuredImagePC, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
