<template>
  <base-modal
    title="유튜브 콘텐츠 가져오기"
    sub-title="콘텐츠 수집을 원하시는 유튜브 채널 주소를 입력해주세요."
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <img
        class="example-img"
        src="/assets/images/channels/channel_modal_img.png"
      />

      <input-basic
        label="채널 주소"
        placeholder="youtube.com/channel/address"
        :default-value="state.url"
        :disabled="state.collecting"
        @updateData="(url) => actions.updateUrl(url)"
      ></input-basic>

      <div class="btn-wrapper">
        <button class="cancel sub-text-s1" @click="actions.closeModal()">
          취소
        </button>
        <button
          class="save sub-text-s1"
          :class="{ active: state.activeSaveBtn && !state.collecting }"
          :disabled="!state.activeSaveBtn || state.collecting"
          @click="actions.connectChannel()"
        >
          {{ state.collecting ? "콘텐츠 수집 중" : "콘텐츠 가져오기" }}
        </button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import { computed, reactive } from "vue";
import helper from "@/helper";
import { useStore } from "vuex";
import cheerio from "cheerio";
import axios from "axios";
import { debounce } from "lodash";

export default {
  name: "ChannelRegisterModal",
  components: { InputBasic, BaseModal },
  emits: ["complete"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      url: "",
      activeSaveBtn: computed(() => {
        return (
          helper.isValidURL(state.url) && state.url.includes("youtube.com/")
        );
      }),

      collecting: false,
    });

    const parseYoutubeChannelUrl = debounce(async () => {
      const resp = await axios.get(
        "https://yjiq150.github.io/coronaboard-crawling-sample/dom"
      );

      const $ = cheerio.load(resp.data); // ❷ HTML을 파싱하고 DOM 생성하기

      const elements = $(".slide p"); // ❸ CSS 셀렉터로 원하는 요소 찾기

      // ➍ 찾은 요소를 순회하면서 요소가 가진 텍스트를 출력하기
      elements.each((idx, el) => {
        // ❺ text() 메서드를 사용하기 위해 Node 객체인 el을 $로 감싸서 cheerio 객체로 변환
        console.log($(el).text());
      });
    }, 1000);

    const actions = {
      closeModal: () => {
        if (!state.collecting) {
          emit("hideModal");
        }
      },
      updateUrl: (url) => {
        state.url = url;
        parseYoutubeChannelUrl();
      },
      connectChannel: async () => {
        if (!state.collecting) {
          state.collecting = true;
          let lastUrlPath = state.url.replace("https://", "").split("/")[3];
          if (lastUrlPath) {
            state.url = state.url.replace(`/${lastUrlPath}`, "");
          }

          let channelRes = await store.dispatch("channels/postChannel", {
            url: state.url,
          });

          let channel = channelRes.data.data;

          let channelMetaRes = await store.dispatch(
            "channels/postChannelMeta",
            {
              channelResourceId: channel.resourceId,
            }
          );

          let channelMeta = channelMetaRes.data.data;

          if (!channelMeta.identifier) {
            state.identifier = channel.identifier; // null
            await store.dispatch("channels/deleteChannel", {
              channelResourceId: channel.resourceId,
            });

            state.collecting = false;
            return;
          }

          await store.dispatch("channels/postChannelGather", {
            channelResourceId: channel.resourceId,
          });

          await store.dispatch("contents/getContents");
          state.collecting = false;
          emit("complete");
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
